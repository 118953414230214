import * as echarts from 'echarts';
import { useEffect } from 'react';

type EChartsOption = echarts.EChartsOption;

function ReportCharts() {
  useEffect(() => {
    // Initialize chart
    const chartDom = document.getElementById('report-charts')!;
    const myChart = echarts.init(chartDom);
    let option: EChartsOption | undefined;

    setTimeout(function () {
      option = {
        legend: {},
        tooltip: {
          trigger: 'axis',
          showContent: false,
        },
        dataset: {
          source: [
            [
              'Projet',
              'Janvier',
              'Février',
              'Mars',
              'Avril',
              'Mai',
              'Juin',
              'Juillet',
              'Août',
              'Septembre',
              'Octobre',
              'Novembre',
              'Décembre',
            ],
            ['Projet 1', 56.5, 82.1, 88.7, 70.1, 53.4, 85.1, 75.3, 68.2, 91.5, 74.6, 69.2, 87.3],
            ['Projet 2', 51.1, 51.4, 55.1, 53.3, 73.8, 68.7, 62.3, 59.1, 67.5, 72.4, 71.8, 65.2],
            ['Projet 3', 40.1, 62.2, 69.5, 36.4, 45.2, 32.5, 48.1, 52.3, 61.7, 49.2, 54.5, 38.9],
            ['Projet 4', 25.2, 37.1, 41.2, 18.0, 33.9, 49.1, 45.0, 40.5, 38.7, 35.3, 42.8, 46.4],
          ],
        },
        xAxis: { type: 'category' },
        yAxis: { gridIndex: 0 },
        grid: { top: '55%' },
        series: [
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
          },
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
          },
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
          },
          {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
          },
          {
            type: 'pie',
            id: 'pie',
            radius: '30%',
            center: ['50%', '25%'],
            emphasis: {
              focus: 'self',
            },
            label: {
              formatter: '{b}: {@2024} ({d}%)',
            },
            encode: {
              itemName: 'Projet',
              value: 'Janvier',
              tooltip: 'Janvier',
            },
          },
        ],
      };

      myChart.on('updateAxisPointer', function (event: any) {
        const xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          const dimension = xAxisInfo.value + 1;
          myChart.setOption<echarts.EChartsOption>({
            series: {
              id: 'pie',
              label: {
                formatter: '{b}: {@[' + dimension + ']} ({d}%)',
              },
              encode: {
                value: dimension,
                tooltip: dimension,
              },
            },
          });
        }
      });

      myChart.setOption<echarts.EChartsOption>(option);
    });

    if (option) {
      myChart.setOption(option);
    }
  });

  return (
    <>
      <div id="report-charts" className="h-screen"></div>
    </>
  );
}

export default ReportCharts;
