import TeamMemberForm from '@/features/Team/components/TeamMemberForm.tsx';
import TeamMemberList from '@/features/Team/components/TeamMemberList.tsx';
import { useState } from 'react';

function Team() {
  const [members, setMembers] = useState([
    { id: 1, name: 'Admin', role: 'Superuser', email: 'test@test.com' },
    { id: 2, name: 'Long', role: 'Manager', email: 'test@test.com' },
  ]);
  const [isFormVisible, setFormVisible] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const handleAddOrUpdateMember = (memberData: any) => {
    if (selectedMember) {
      setMembers((prevMembers: any) =>
        prevMembers.map((member: any) =>
          member.id === selectedMember['id'] ? { ...member, ...memberData } : member,
        ),
      );
    } else {
      setMembers((prevMembers: any) => [...prevMembers, { id: Date.now(), ...memberData }]);
    }
    setSelectedMember(null);
    setFormVisible(false);
  };

  const handleEditMember = (member: any) => {
    setSelectedMember(member);
    setFormVisible(true);
  };

  const handleDeleteMember = (memberId: any) => {
    setMembers((prevMembers) => prevMembers.filter((member) => member.id !== memberId));
  };

  const handleCancel = () => {
    setSelectedMember(null);
    setFormVisible(false);
  };

  return (
    <>
      <div className="p-8">
        <h1 className="text-3xl font-bold mb-6">Gestion de l'équipe</h1>
        <button
          onClick={() => setFormVisible(true)}
          className="mb-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
        >
          Ajouter un Membre
        </button>

        {isFormVisible && (
          <TeamMemberForm
            onSubmit={handleAddOrUpdateMember}
            onCancel={handleCancel}
            selectedMember={selectedMember}
          />
        )}

        <TeamMemberList members={members} onEdit={handleEditMember} onDelete={handleDeleteMember} />
      </div>
    </>
  );
}

export default Team;
