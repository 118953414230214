import DocumentTable from '@/features/Document/components/DocumentTable.tsx';

function Document() {
  return (
    <>
      <DocumentTable />
    </>
  );
}

export default Document;
