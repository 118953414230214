import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  Login,
  Home,
  Team,
  Project,
  Calender,
  Document,
  Report,
  Budget,
  Support,
  Dashboard,
} from './pages';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Auth Path */}
          <Route path="/login" element={<Login />} />
          {/* Home Path */}
          <Route path={'/'} element={<Home />}>
            {/* Sidebar Outlet */}
            <Route path={'/'} element={<Dashboard />} />
            <Route path={'/dashboard'} element={<Dashboard />} />
            <Route path={'/team'} element={<Team />} />
            <Route path={'/project'} element={<Project />} />
            <Route path={'/calender'} element={<Calender />} />
            <Route path={'/document'} element={<Document />} />
            <Route path={'/report'} element={<Report />} />
            <Route path={'/budget'} element={<Budget />} />
            <Route path={'/support'} element={<Support />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
