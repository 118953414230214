import { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Notification from '@/features/Home/components/Notification.tsx';
import Searchbar from '@/features/Home/components/Searchbar.tsx';
import SidebarNavigation from '@/features/Home/components/SidebarNavigation.tsx';
import UserNavigation from '@/features/Home/components/UserNavigation.tsx';
import { Outlet } from 'react-router-dom';

function Home() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div>
        <div>
          <SidebarNavigation open={sidebarOpen} onClose={setSidebarOpen} />
        </div>
        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 lg:mx-auto lg:max-w-7xl lg:px-8">
            <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
              <button
                type="button"
                onClick={() => setSidebarOpen(true)}
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>

              {/* Separator */}
              <div aria-hidden="true" className="h-6 w-px bg-gray-200 lg:hidden" />

              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                {/* Searchbar */}
                <Searchbar />

                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <Notification />

                  {/* Separator */}
                  <div
                    aria-hidden="true"
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                  />

                  {/* User Profile dropdown */}
                  <UserNavigation />
                </div>
              </div>
            </div>
          </div>

          <main className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              {/* Your content */}
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Home;
