import BudgetCharts from '@/features/Budget/components/BudgetCharts.tsx';

function Budget() {
  return (
    <>
      <BudgetCharts />
    </>
  );
}

export default Budget;
