import SupportForm from '@/features/Support/components/SupportForm.tsx';
import { useState } from 'react';

function Support() {
  const [, setFormVisible] = useState(false);

  const handleSupportRequest = (formData: any) => {
    console.log('Support Request Data:', formData);
    setFormVisible(false); // Hide form after submission
    // Add your logic to handle support request submission (e.g., API call)
  };

  const handleCancel = () => {
    setFormVisible(false);
  };

  return (
    <>
      <div className="p-8">
        <SupportForm onSubmit={handleSupportRequest} onCancel={handleCancel} />
      </div>
    </>
  );
}

export default Support;
