import TeamMemberItem from './TeamMemberItem';

const TeamMemberList = ({ members, onEdit, onDelete }: any) => {
  return (
    <div className="space-y-4">
      {members.map((member: any) => (
        <TeamMemberItem key={member.id} member={member} onEdit={onEdit} onDelete={onDelete} />
      ))}
    </div>
  );
};

export default TeamMemberList;
