import Card from '@/features/Dashboard/components/Card.tsx';
import DashboardChart from '@/features/Dashboard/components/DashboardChart.tsx';
import DashboardTable from '@/features/Dashboard/components/DashboardTable.tsx';

function Dashboard() {
  return (
    <>
      <div className="flex">
        {/* Main Content */}
        <div className="w-full">
          <div className="p-8 space-y-8">
            {/* Stat Cards */}
            <div className="grid grid-cols-3 gap-8">
              <Card title="Total Utilisateurs" value="150" />
              <Card title="Nouveaux Projets" value="12" />
              <Card title="Revenus Mensuels" value="€18,000" />
            </div>

            {/* Charts */}
            <DashboardChart />

            {/* Tables */}
            <DashboardTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
