import CalenderMonthly from '@/features/Calender/components/CalenderMonthly.tsx';

function Calender() {
  return (
    <>
      <CalenderMonthly />
    </>
  );
}

export default Calender;
