import ProjectForm from '@/features/Project/components/ProjectForm.tsx';
import { useState } from 'react';

function Project() {
  const [, setFormVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleCreateOrUpdateProject = (projectData: any) => {
    console.log('Project Data:', projectData);
    setFormVisible(false); // Hide form after submission
    // Add your logic to create or update the project here
  };

  const handleCancel = () => {
    setFormVisible(false);
    setSelectedProject(null);
  };

  return (
    <>
      <div className="p-8">
        <ProjectForm
          project={selectedProject}
          onSubmit={handleCreateOrUpdateProject}
          onCancel={handleCancel}
        />
      </div>
    </>
  );
}

export default Project;
