import * as echarts from 'echarts';
import { useEffect } from 'react';

type EChartsOption = echarts.EChartsOption;

const DashboardChart = () => {
  useEffect(() => {
    // Initialize chart
    const chartDom = document.getElementById('dashboard-charts')!;
    const myChart = echarts.init(chartDom);
    let option: EChartsOption | undefined;

    setTimeout(function () {
      option = {
        title: {
          text: 'Revenus Mensuels',
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Revenus',
            type: 'bar',
            data: [12000, 15000, 8000, 18000, 10000, 20000],
            itemStyle: {
              color: '#4A90E2',
            },
          },
        ],
      };

      myChart.on('updateAxisPointer', function (event: any) {
        const xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          const dimension = xAxisInfo.value + 1;
          myChart.setOption<echarts.EChartsOption>({
            series: {
              id: 'pie',
              label: {
                formatter: '{b}: {@[' + dimension + ']} ({d}%)',
              },
              encode: {
                value: dimension,
                tooltip: dimension,
              },
            },
          });
        }
      });

      myChart.setOption<echarts.EChartsOption>(option);
    });

    if (option) {
      myChart.setOption(option);
    }
  });

  return (
    <>
      <div id="dashboard-charts" className="h-96"></div>
    </>
  );
};

export default DashboardChart;
