import classNames from '@/utils/ClassName.ts';
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid';

const documents = [
  {
    id: 1,
    title: 'tmp_file1.pdf',
    created_by: 'Admin',
    created_at: new Date(Date.now() - 864e5).toLocaleString(),
    is_validated: true,
    archived_at: new Date().toLocaleString(),
  },
  {
    id: 2,
    title: 'tmp_file2.pdf',
    created_by: 'Admin',
    created_at: new Date(Date.now() - 864e5).toLocaleString(),
    is_validated: true,
    archived_at: new Date().toLocaleString(),
  },
  {
    id: 3,
    title: 'tmp_file3.pdf',
    created_by: 'Admin',
    created_at: new Date(Date.now() - 864e5).toLocaleString(),
    is_validated: false,
    archived_at: new Date().toLocaleString(),
  },
  {
    id: 4,
    title: 'tmp_file4.pdf',
    created_by: 'Admin',
    created_at: new Date(Date.now() - 864e5).toLocaleString(),
    is_validated: false,
    archived_at: new Date().toLocaleString(),
  },
  {
    id: 5,
    title: 'tmp_file5.pdf',
    created_by: 'Admin',
    created_at: new Date(Date.now() - 864e5).toLocaleString(),
    is_validated: true,
    archived_at: new Date().toLocaleString(),
  },
];

interface IDocumentTableColumns {
  id: number;
  title: string;
  created_by: string;
  created_at: string;
  is_validated: boolean;
  archived_at: string;
}

const statuses = {
  Validated: 'text-green-400 bg-green-400/10',
  NotValidated: 'text-rose-400 bg-rose-400/10',
};

function DocumentTable() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Documents</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title, email and role.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Ajouter un document
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                  >
                    Titre
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Créé par
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Date de création
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Validé
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Date d'archivage
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span className="sr-only">Modifier</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {documents.map((document: IDocumentTableColumns) => (
                  <tr key={document.id} className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {document.title}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {document.created_by}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {document.created_at}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                        <div
                          className={classNames(
                            statuses[document.is_validated ? 'Validated' : 'NotValidated'],
                            'flex-none rounded-full p-1',
                          )}
                        >
                          <div className="h-1.5 w-1.5 rounded-full bg-current" />
                        </div>
                        <div className="hidden sm:block">
                          {document.is_validated ? 'Validé' : 'Non validé'}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {document.archived_at}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                      <a href="#" className="text-indigo-600 hover:text-indigo-900">
                        Modifier<span className="sr-only">, {document.id}</span>
                      </a>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                      <a href="#" className="text-indigo-600 hover:text-indigo-900">
                        <DocumentArrowDownIcon aria-hidden="true" className="h-6 w-6" />
                        <span className="sr-only">, {document.id}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentTable;
