import ReportCharts from '@/features/Report/components/ReportCharts.tsx';

function Report() {
  return (
    <>
      <ReportCharts />
    </>
  );
}

export default Report;
