const TeamMemberItem = ({ member, onEdit, onDelete }: any) => {
  return (
    <div className="p-4 bg-white shadow-md rounded-md flex justify-between items-center">
      <div>
        <h3 className="text-lg font-semibold">{member.name}</h3>
        <p className="text-sm text-gray-600">{member.role}</p>
        <p className="text-sm text-gray-600">{member.email}</p>
      </div>
      <div className="flex space-x-2">
        <button
          onClick={() => onEdit(member)}
          className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
        >
          Modifier
        </button>
        <button
          onClick={() => onDelete(member.id)}
          className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Supprimer
        </button>
      </div>
    </div>
  );
};

export default TeamMemberItem;
